<template>
  <div class="containvv">
    <div class="contain-top" @click="back">
      <img src="@/assets/icon_left.png" alt="" />
      <span>返回答题首页</span>
    </div>
    <div class="list" v-loading="loading">
      <div
        class="box"
        v-for="(item, index) in list"
        :key="index"
        @click="godetails(item.id)"
      >
        <div class="title">{{ item.teacher_question_bank.title }}</div>
        <div class="bottom">
          <div class="bottom-left">
            <span>{{
              item.teacher_question_bank.type == 1
                ? "【单选题】"
                : item.teacher_question_bank.type == 2
                ? "【多选题】"
                : item.teacher_question_bank.type == 3
                ? "【填空题】"
                : item.teacher_question_bank.type == 4
                ? "【简答题】"
                : "【判断题】"
            }}</span>
            <span>{{ item.answer_at }}</span>
          </div>
          <div class="bottom-right">
            <span>查看详情</span>
            <img src="@/assets/icon_right_blue.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      list: [],
      query: {
        page: 1,
        limit: 10,
      },
      loading: true,
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚轮滚动事件
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll); //  离开页面清除（移除）滚轮滚动事件
  },
  methods: {
    handleScroll() {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        if (!this.noMore) {
          console.log("触底");
          this.query.limit += 10;
          this.getList();
        }
        //写后台加载数据的函数
        // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
      }
    },
    back() {
      this.$router.go(-1);
      // thisuni.getStorageSync('storage_key')
    },
    godetails(id) {
      this.$router.push("/question/record_details?id=" + id);
    },
    getList() {
      this.loading = true;
      request({
        url: "/api/teacherend/teacherQuestionBank/log",
        method: "get",
        params: this.query,
      }).then((response) => {
        this.list = response.data.data;
        this.list.forEach((item) => {
          item.teacher_question_bank = JSON.parse(
            item.teacher_question_bank_snap
          );
          item.teacher_question_bank.title = item.teacher_question_bank.title.replaceAll("{input}", "_____");
        });
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.containvv {
  min-height: calc(100vh - 160px);
  background: #ffffff;
  border-radius: 9px;
  padding: 30px;
  .el-loading-spinner {
    position: fixed;
    top: 400px;
    left: 230px;
  }
  .contain-top {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 15px;
      height: 15px;
    }
    span {
      margin-left: 10px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #506176;
    }
  }
  .list {
    margin-top: 25px;
    .box {
      cursor: pointer;
      padding: 20px 0;
      border-bottom: 1px solid #f9fafd;
      .title {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 34px;
      }
      .bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        .bottom-left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            &:nth-child(1) {
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #3d7eff;
            }
            &:nth-child(2) {
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
        }
        .bottom-right {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3d7eff;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>